// extracted by mini-css-extract-plugin
export var onlineMarketplaceAchievementsSection = "z_dC";
export var onlineMarketplaceCardBorderSection = "z_dl";
export var onlineMarketplaceCardRowIconsSection = "z_dr";
export var onlineMarketplaceClientQuotesSection = "z_dD";
export var onlineMarketplaceCycleDevSection = "z_dm";
export var onlineMarketplaceCycleDevSection__testimonial = "z_dn";
export var onlineMarketplaceDomainExpertiseSection = "z_dp";
export var onlineMarketplaceExceptionSection = "z_dq";
export var onlineMarketplaceFeaturedCaseSection = "z_ds";
export var onlineMarketplaceFeaturesSection = "z_dv";
export var onlineMarketplaceFeaturesSection__box = "z_dw";
export var onlineMarketplaceRequirementSection = "z_dx";
export var onlineMarketplaceSuccessStoriesSection = "z_dz";
export var onlineMarketplaceSuccessfulSection = "z_dt";
export var onlineMarketplaceTechStackSection = "z_dy";
export var onlineMarketplaceWhyHireSection = "z_dB";